<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{name: 'dashboard'}">Dashboard</router-link></li>
        <li class="breadcrumb-item active">Attendances</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">{{ searchString.replace('-',' ').toUpperCase() }}</h2>
          </div>
          <div class="col text-end">
          
          </div>
      </div>
        <div class="t-layout">
          <div class="row">
              <div class="col-md-12">
                <div v-if="this.$store.state.loader">
                    <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
                </div>
                <div v-else class="card">
                <div class="card-header">
                  <flash-message class="myCustomClass"></flash-message>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search data"
                      ></b-form-input>
                    </div>
                    <div class="col-md-9 tx-right">
                      <div class="page-number-input custom-box">
                        <label>Go to Page:</label>
                        <b-form-input
                        class="page-number"
                        v-model="currentPage"
                        type="number"
                        min="1"
                        :max="attandanceData.length > 0 ? Math.ceil(attandanceData.length/perPage) : 1"
                      ></b-form-input>
                      </div>
                      <div class="pagination-wrapper custom-box">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="attandanceData.length"
                          :per-page="perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                  <b-table
                    class="table table-custom"
                    :items="attandanceData"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :filter="filter"
                    :sort-compare="mySortCompare"
                    :sort-desc.sync="sortDesc"
                    :sort-by.sync="sortBy"
                    sticky-header
                  >
                  <template #head()="{ label, field: { key, sortable }}">
                    {{ label }}
                    <template v-if="sortable">
                      <template>
                        <b-img v-if="sortBy !== key" :src="sortIconNeutral" style="width: 0.65rem; height: 1rem;"></b-img>
                        <span title="Sort By ascending" v-else-if="sortDesc"><b-img :src="sortIconAsc" style="width: 0.65rem; height: 1rem;"></b-img></span>
                        <span v-else title="Sort By descending" ><b-img :src="sortIconDesc" style="width: 0.65rem; height: 1rem;"></b-img></span>


                      </template>
                    </template>
                  </template>
                  <template v-slot:cell(status)="data">
                      {{ data.item.status_timein }} / {{ data.item.status_timeout }}
                  </template>
                  <template v-slot:cell(timein)="data">
                      {{ formatTime(data.item.timein) }}
                  </template>
                  <template v-slot:cell(timeout)="data">
                      {{ data.item.timeout != null || data.item.timeout != null != '' ? formatTime(data.item.timeout) : ''  }}
                  </template>
                  <!-- <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                          <div class="d-flex justify-content-md-around">
                              <a v-if="$store.state.permittedActions.includes(31)" class="button button1" title="Edit" @click.prevent="setPageNumber(data.item.id)" ><i class="icon-pencil"></i></a>
                              <a v-if="$store.state.permittedActions.includes(32)" class="button button1" title="Delete" @click.prevent="deleteAttendance(data.item.id)"><i class="icon-bin"></i></a>
                          </div>
                      </div>
                  </template> -->
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
export default {
    data() {
        return {
            filter: null,
            currentPage: 1,
            pageIncreased: false,
            perPage: 15,
            updatedPerPage: null,
            sortIconNeutral: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconDesc: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconAsc: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortBy: null,
            sortDesc: false,
            searchString: this.$route.params.filterString,
            fields: [
                {
                    key: 'date',
                    sortable: true,
                    tdAttr: { 'custom-label'  : "Date:"}
                },
                {
                    key: 'employee',
                    sortable: true,
                    tdAttr: { 'custom-label'  : "Employee:"}
                },
                {
                    key: 'timein',
                    label: 'Time In',
                    tdAttr: { 'custom-label'  : "Time In:"}
                },
                {
                    key: 'timeout',
                    label: 'Time out',
                    tdAttr: { 'custom-label'  : "Time out:"}
                },
                {
                    key: 'totalhours',
                    label: 'Total Hours',
                    tdAttr: { 'custom-label'  : "Total Hours:"}
                },
                {
                    key: 'status',
                    tdAttr: { 'custom-label'  : "Status:"}
                },
                /* {
                    key: 'action',
                    tdAttr: { 'custom-label'  : "Action:"}
                } */
            ]
        }
    },
    computed: {
        attandanceData(){
            return this.$store.state.filteredAttendancesData;
        },
        /* perPage: {
          get: function() {
            if(this.pageIncreased == false && this.updatedPerPage ==null){
              return this.$store.state.filteredAttendancesData.filter((att) => att.date == moment(new Date()).format("YYYY-MM-DD")).length;
            }
            else{
              console.log('updated per page', this.updatedPerPage);
              return this.updatedPerPage;
            }
            
          },
          set: function(newValue) {
            return this.updatedPerPage = newValue;
          }
        }, */
        /* rows(){
          return this.attandanceData.data.length;
        } */
    },
    created(){
        
    },
    mounted() {
        this.$store.dispatch('switchLoader',true);
        this.$store.dispatch('loadAttendancesdata');
        this.$store.dispatch('switchLoader',true);
        setTimeout(this.filterAtt,1000);
        if(localStorage.getItem('page_number')){
          this.currentPage = localStorage.getItem('page_number');
          localStorage.removeItem('page_number');
        }
        console.log('late-in', this.attandanceData);
    },
    
    methods:{ 
      filterAtt(){
        this.$store.dispatch('loadFilterAttendancedata', this.searchString);
      },
      mySortCompare(a, b, key) {
        /*  var firstDate;
         var secondDate;
         if(key == 'from'){
           firstDate = new Date(a.leavefrom);
           secondDate = new Date(b.leavefrom);
         }
         else if(key == 'to'){
           firstDate = new Date(a.leaveto);
           secondDate = new Date(b.leaveto);
         }
         else if(key == 'return_date'){
           firstDate = new Date(a.returndate);
           secondDate = new Date(b.returndate);
         }
         if(key == 'from' || key == 'to' || key == 'return_date'){
          firstDate = firstDate.getTime();
          secondDate = secondDate.getTime();
          return firstDate - secondDate;
         }
         else{
           return false;
         } */
      },
      setPageNumber(id){
        localStorage.setItem('page_number',this.currentPage);
        this.$router.push({ name: 'attendance-update', params: { id: id }}); 
        
      },
      onFiltered(filteredItems) {
        if(this.filter == ''){
          this.currentPage = 1;
        }
      },
      formatTime(time){
        return moment(time).format('hh:mm A');
      },
      deleteAttendance(id){
        if(confirm("Are you sure, you want to delete this attendance ?")){
          axios.get('/attendance/delete/'+id)
            .then(({data}) => {
              if(data.status == 'success')
              this.flashMessage.success({
              message: 'Attendance deleted successfully',
              time: 3000
            });
            this.$store.dispatch('loadAttendancesdata');
            })
        }
      }
    }
}
</script>
